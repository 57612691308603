@import '../../../styles/mixins';
@import '../../../styles/variables';

.startPage {
  min-height: 100vh;
  width: 100%;
  padding-bottom: 2em;
  background-color: $contentBackground;
  @include page-layout();
}

@import '../../../styles/variables';
@import '../../../styles/mixins';

.errorPage {
  @include page-layout();

  .header {
    margin: 4rem 0;
    position: relative;
  }

  .underlined::after {
    content: '';
    box-sizing: border-box;
    height: 0.3rem;
    width: 6rem;
    border-bottom: 4px solid rgba($secondary, 1);
    position: absolute;
    bottom: -1rem;
    left: 0;
  }

  .pillButton {
    border-radius: 2rem;
    text-decoration: none;
    padding: 1rem 1.33rem 0.825rem 1.33rem;
    display: inline-block;
    margin-bottom: 1rem;
    text-align: center;
    background-color: $black;
    color: white !important;
  }
}

@import '../../../styles/mixins';
@import '../../../styles/variables';

.forbidden {
  min-height: 100vh;
  width: 100%;
  padding: 10rem 2em;
  background-color: $contentBackground;

  .center {
    margin: auto;
    width: fit-content;

    .link {
      margin-top: 2rem;
    }
  }
}

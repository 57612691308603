@import '../../../styles/mixins';

.salesWizardPage {
  h1 {
    @include page-layout();
  }
  h2 {
    font-size: 1.375rem;
  }
  h3 {
    font-size: 1.25rem;
  }
}

@import 'mixins';
@import 'variables';

@mixin fontFamily() {
  font-family: $fontFamilyMuli;
}

@mixin bold() {
  font-style: normal;
  font-weight: bold !important;
  @include fontFamily();
}

@mixin medium() {
  font-style: normal;
  font-weight: 500;
  @include fontFamily();
}

html,
body {
  @include fontFamily();
  line-height: 1.5;
}

* {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  @include fontFamily();
  font-size: 1rem;
}

@include all-headings() {
  @include bold();
}

h1 {
  font-size: 2.5rem;
  margin: 1.25rem 0;
}

h2 {
  font-size: 1.375rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

p {
  color: $black;
  @include medium();
}

.displayFont,
:global(.displayFont) {
  font-size: 2.5rem;
  @include bold();
}

.smallFont,
:global(.smallFont) {
  font-size: 1rem;
  @include medium();
}

.tinyFont,
:global(.tinyFont) {
  font-size: 1.2rem;
  @include bold();
}

@import 'variables';

@mixin page-layout() {
  padding: 0;

  @include desktop() {
    padding: 0;
  }
}

@mixin small-mobile {
  @media screen and (max-width: $min-width-mobile) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $max-width-mobile) {
    @content;
  }
}

@mixin devices {
  @media screen and (max-width: $max-width-tablet) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $min-width-tablet) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $min-width-desktop) {
    @content;
  }
}

@mixin all-headings() {
  @for $index from 1 through 6 {
    h#{$index} {
      @content;
    }
  }
}

/**
 * A mixin which helps you to add depth to elements according to the Google Material Design spec:
 * http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality
 *
 * Please note that the values given in the specification cannot be used as is. To create the same visual experience
 * the blur parameter has to be doubled.
 *
 * Adapted from a LESS version at https://medium.com/@Florian/freebie-google-material-design-shadow-helper-2a0501295a2d
 *
 * Original Author: Florian Kutschera (@gefangenimnetz), Conceptboard GmbH (@conceptboardapp)
 *
 * Example usage:
 *
 * .card {
 *     width: 95px;
 *     height: 95px;
 *     background: #f4f4f4;
 *     -webkit-transition: all 250ms;
 *     -moz-transition: all 250ms;
 *     transition: all 250ms;
 *     @include box_shadow(1);
 *     &:hover {
 *         @include box_shadow(3);
 *         -webkit-transform: translateY(-5px);
 *         -moz-transform: translateY(-5px);
 *         transform: translateY(-5px);
 *     }
 * }
 *
 */
@mixin box_shadow($level: 0) {
  @if $level == 0 {
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.23), 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

@mixin hover_card() {
  transition: all 200ms ease-in-out;
  transform: translateY(0px);
  @include box_shadow(1);

  &:hover {
    transform: translateY(2px);
    @include box_shadow(3);
  }
}

@mixin background-image-fit() {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin aspect-ratio($width, $height, $elementClass: '.content') {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > #{$elementClass} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin dialogBubble(
  $border-width,
  $arrow-size,
  $colour: $descriptionCardColor,
  $border-colour: darken($descriptionCardColor, 10%),
  $top: unset,
  $right: unset,
  $bottom: unset,
  $left: unset,
  $direction: 'up'
) {
  position: relative;
  background: $colour;
  border: $border-width solid $border-colour;
  padding: 1rem;
  border-radius: $border-radius;
  font-size: 14px;
  margin: $arrow-size;
  width: calc(100% - 20px);

  &:before,
  &:after {
    bottom: $bottom;
    left: $left;
    top: $top;
    right: $right;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(182, 235, 218, 0);
    border-width: $arrow-size;

    @if ($direction == 'up') {
      border-bottom-color: $colour;
      border-width: calc(#{$arrow-size} + 2 * #{$border-width});
      margin-bottom: 0;
    } @else if($direction == 'down') {
      border-top-color: $colour;
      border-width: calc(#{$arrow-size} + #{$border-width});
      margin-left: -$arrow-size;
      margin-top: calc(-#{$arrow-size} - #{$border-width});
    } @else if($direction == 'left') {
      border-right-color: $colour;
      margin-top: -$arrow-size;
    } @else if($direction == 'right') {
      border-left-color: $colour;
      border-width: calc(#{$arrow-size} + #{$border-width});
      margin-top: calc(-#{$arrow-size} - 2 * #{$border-width});
    }
  }
  &:before {
    border-color: rgba(142, 224, 198, 0);
    border-width: calc(#{$arrow-size} + #{$border-width});

    @if ($direction == 'up') {
      border-bottom-color: $border-colour;
      border-width: calc(#{$arrow-size} + 2 * #{$border-width});
      margin-bottom: -#{$border-width};
    } @else if($direction == 'down') {
      border-top-color: $border-colour;
      margin-left: calc(-#{$arrow-size} - #{$border-width});
      margin-top: -$arrow-size;
    } @else if($direction == 'left') {
      border-right-color: $border-colour;
      margin-top: calc(-#{$arrow-size} - #{$border-width});
    } @else if($direction == 'right') {
      border-left-color: $border-colour;
      border-width: $arrow-size;
      margin-top: calc(-#{$arrow-size} - #{$border-width});
    }
  }
}

@mixin simpleDialogBubble($direction: 'left') {
  @include dialogBubble(
    1px,
    20px,
    $descriptionCardColor,
    darken($descriptionCardColor, 10%),
    50%,
    100%,
    unset,
    unset,
    $direction
  );
  display: none;
  width: 27rem;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  margin: 0 0 0 50px;
  z-index: 10;

  @if ($direction == 'down') {
    @include dialogBubble(
      1px,
      20px,
      $descriptionCardColor,
      darken($descriptionCardColor, 10%),
      119%,
      16%,
      unset,
      unset,
      $direction
    );
    position: absolute;
    width: 27rem;
    height: 7rem;
    bottom: 425%;
    right: -503%;
  } @else if ($direction == 'up') {
    @include dialogBubble(
      1px,
      20px,
      $descriptionCardColor,
      darken($descriptionCardColor, 10%),
      unset,
      16%,
      100%,
      unset,
      $direction
    );
    position: absolute;
    width: 27rem;
    height: 7rem;
    bottom: -560%;
    right: -510%;
  } @else if ($direction == 'right') {
    @include dialogBubble(
      1px,
      20px,
      $descriptionCardColor,
      darken($descriptionCardColor, 10%),
      72%,
      -9%,
      unset,
      unset,
      $direction
    );
    position: absolute;
    width: 27rem;
    height: 7rem;
    bottom: 57%;
    right: 100%;
  }
}

@mixin applyIconFont() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icon-font-family !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin button() {
  text-decoration: none;
  padding: 0.67rem 3rem;
  text-align: center;
  border-radius: $button-border-radius;
  box-sizing: border-box;
  display: block;
  max-width: 350px;
  margin: 1rem 0;
  transition: all 300ms;
  color: $black;

  &[disabled],
  .disabled {
    background-color: $lighterGrey;
    cursor: default;
  }

  &.green {
    background-color: $secondary;

    &[disabled],
    .disabled {
      background-color: rgba($secondary, 0.4);
    }
  }

  &.upload {
    background-color: $uploadButtonGrey;
    display: flex;
    justify-content: space-around;

    &[disabled],
    .disabled {
      border-color: $disabledGrey;
    }
  }

  &.white {
    background-color: white;
    border: 1px solid $black;

    &[disabled],
    .disabled {
      border-color: rgba($black, 0.6);
    }
  }

  &.outlined {
    background-color: white;
    border: 2px solid $secondary;
  }

  &.close {
    border-radius: 50%;
    font-size: 0;
    padding: 0;
    width: 3rem;
    height: 3rem;

    &:before {
      content: '\00d7';
      font-size: 2rem;
    }
  }

  @include mobile() {
    min-width: unset;
    min-height: unset;
    padding: 0.67rem 1rem;
    @include ellipsis();
  }
}

@mixin responsive-table() {
  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    thead {
      th {
        text-align: left;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: $lighterGrey;
        }
      }

      td {
        &.actions {
          .buttons {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            justify-content: flex-end;

            button,
            .button {
              background: transparent;
              border-radius: 16px;
              color: #32313c;
              height: 34px;
              margin: 0.5rem;
              padding: 0.2em 1em;
              text-align: center;
              text-decoration: none;
              @include ellipsis();
            }

            .deleteButton {
              border: 1px solid #32313c;
            }

            .button {
              border: 2px solid #2aecae;
            }
          }
        }
      }
    }

    caption {
      display: none;
      font-size: 1.5em;
      margin: 0.5em 0 0.75em;
    }

    tr {
      padding: 0.35em;
    }

    th,
    td {
      padding: 0.625em;
      text-align: center;

      &.actions {
        min-width: 300px;
      }

      &.comment {
        input {
          margin: 0;
        }
      }
    }

    th {
      font-size: 0.85em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      @include ellipsis();
    }

    @include devices() {
      border: 0;

      caption {
        font-size: 1.3em;
      }

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
      }

      td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;

        &::before {
          /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
          content: attr(data-label);
          float: left;
          font-weight: bold;
          margin-right: auto;
          text-transform: uppercase;
        }
      }

      td:last-child {
        border-bottom: 0;
      }
    }
  }
}

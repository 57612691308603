@import 'variables';

html {
  box-sizing: border-box;
  /* 1rem = 10px */
  font-size: 100%;
}

body {
  font-size: 1rem;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;

  &:focus {
    outline: none;
  }
}

/* Reset `button` and button-style `input` default styles */
input[type='button'],
button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}

input,
textarea {
  padding: 1rem;
  margin: 0;
}
input[type='checkbox'] {
  padding: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline-offset: 0;
  outline: none;
}

html,
body {
  background-color: $backgroundSite;
  height: 100%;
  label > span {
    font-size: 1rem;
  }
}

body {
  min-height: 100vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

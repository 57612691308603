@import '../styles/mixins';
@import '../styles/variables';

/* GLOBAL APP STYLES */
.loadingSpinners {
  text-align: center;
}

.App {
  background-color: white;
  position: relative;
  min-height: 100vh;
  width: 100%;

  h1.display {
    font-size: 3rem;
  }
  h1,
  .h1 {
    font-size: 2.5rem;
    margin: 2rem 0;
    font-weight: inherit;
  }
  h2,
  .h2 {
    font-size: 1.375rem;
    font-weight: inherit;
  }
  h3,
  .h3 {
    font-size: 1.25rem;
    font-weight: inherit;
  }
  h4,
  .h4 {
    font-size: 1rem;
    font-weight: inherit;
  }

  h5,
  h6,
  .h5,
  .h6 {
    font-size: 0.875rem;
    margin: 0;
    padding: 0;
    font-weight: inherit;
  }
  p,
  .p {
    font-size: 1rem;
    font-weight: inherit;
  }
  small,
  .small {
    font-size: 14px;
    font-weight: 600;
  }

  button,
  button span,
  a span,
  a > span {
    font-size: 1rem;
    font-weight: bold;
  }

  select,
  span {
    font-size: 1.125rem;
  }
  .small {
    font-size: 0.875rem;
  }

  .AppContent {
    min-height: calc(100vh - #{2 * $app-header-height});
    position: relative;
    &.NoGutters {
      padding: 0 0;
    }
  }

  @include desktop() {
    .AppContent {
      min-height: calc(100vh - #{$app-header-height});
    }

    .dealerWarning {
      margin: 1rem $appGutters;
    }
  }
}

.switchWrapper {
  position: relative;
}

.switchWrapper > div {
  position: absolute;
  width: 100%;
}

@media print {
  .App {
    margin-top: 0;
    background: white;
  }
  .AppContent {
    padding-top: 0;
  }
}

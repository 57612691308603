@import 'variables';

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/mulish/Mulish-Regular.ttf') format('truetype');
  font-weight: $fontWeightNormal;
  font-style: normal;
}

@font-face {
  font-family: 'MulishItalic';
  src: url('../assets/fonts/mulish/Mulish-Italic.ttf') format('truetype');
  font-weight: $fontWeightNormal;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/mulish/Mulish-SemiBold.ttf') format('truetype');
  font-weight: $fontWeightSemiBold;
  font-style: normal;
}
@font-face {
  font-family: 'MulishItalic';
  src: url('../assets/fonts/mulish/Mulish-SemiBoldItalic.ttf') format('truetype');
  font-weight: $fontWeightSemiBold;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/mulish/Mulish-Bold.ttf') format('truetype');
  font-weight: $fontWeightBold;
  font-style: bold;
}
// @font-face {
//   font-family: 'Muli';
//   src: url('../assets/fonts/muli/Muli-Regular.woff2') format('woff2'),
//     url('../assets/fonts/muli/Muli-Regular.woff') format('woff'),
//     url('../assets/fonts/muli/Muli-Regular.ttf') format('truetype');
//   font-weight: $fontWeightNormal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'MuliItalic';
//   src: url('../assets/fonts/muli/Muli-Italic.woff2') format('woff2'),
//     url('../assets/fonts/muli/Muli-Italic.woff') format('woff'),
//     url('../assets/fonts/muli/Muli-Italic.ttf') format('truetype');
//   font-weight: $fontWeightNormal;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Muli';
//   src: url('../assets/fonts/muli/Muli-SemiBold.woff2') format('woff2'),
//     url('../assets/fonts/muli/Muli-SemiBold.woff') format('woff'),
//     url('../assets/fonts/muli/Muli-SemiBold.ttf') format('truetype');
//   font-weight: $fontWeightSemiBold;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'MuliItalic';
//   src: url('../assets/fonts/muli/Muli-SemiBoldItalic.woff2') format('woff2'),
//     url('../assets/fonts/muli/Muli-SemiBoldItalic.woff') format('woff'),
//     url('../assets/fonts/muli/Muli-SemiBoldItalic.ttf') format('truetype');
//   font-weight: $fontWeightSemiBold;
//   font-style: italic;
// }

@import '../../../styles/mixins';
@import '../../../styles/variables';

$active-border-height: 3px;
$logo-mobile-width: 10rem;
$button-mobile-size: 3rem;
$button-desktop-size: 4.4rem;

@mixin menu-border($color: $primary) {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: $active-border-height;
  background: $color;
}

.appHeader {
  align-items: flex-start;
  background-color: $salgBrand;
  box-sizing: border-box;
  display: flex;
  height: $app-header-height;
  justify-content: space-between;
  padding: 0 3rem;
  width: 100%;
  z-index: 10001;
  flex-wrap: nowrap;
  top: 0;

  .logo {
    align-items: center;
    display: flex;
    height: $app-header-height;
    order: 1;
    width: $logo-mobile-width;

    .AppLink {
      margin: 0;
      max-height: 100%;
      width: 100%;
      height: 2.5rem;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .user {
    align-items: center;
    color: $secondary;
    display: none;
    height: $app-header-height;
    justify-content: flex-end;
    order: 4;
    right: 4em;
    text-align: right;
    width: calc(100% - #{$logo-mobile-width});

    .info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 1rem;
      font-weight: $fontWeightSemiBold;
      p {
        font-size: 1rem;
        margin: 0;
        @include ellipsis();
      }
    }
    button {
      background-color: $secondary;
    }
  }

  .menu {
    display: flex;
    height: $app-header-height;
    place-items: flex-end;
    margin: $active-border-height 0 0;
    max-width: 50rem;
    order: 3;
    white-space: pre-wrap;
    gap: 2.5rem;
    svg {
      path {
        fill: $salgwhite;
      }
    }

    .menuItem {
      display: flex;
      flex: 0 0 auto;
      height: 100%;
      justify-content: center;
      line-height: 1.25rem;
      margin: 0 0.625rem;
      position: relative;
      align-items: center;
      gap: 0.5rem;

      &.active {
        a {
          font-weight: bold;
        }
      }

      #{$a-tags} {
        text-decoration: none;
      }

      a {
        align-items: center;
        color: $salgwhite;
        display: flex;
        font-family: $fontFamilyMuli;
        font-style: normal;
        font-weight: normal;
        height: $app-header-height;
        position: relative;
        text-align: center;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .maintenanceMessage {
    color: #f96060;
    display: flex;
    height: 100%;
    font-weight: bold;
    align-items: center;
    order: 2;
  }

  @include desktop() {
    .logo {
      width: 6.25rem;
      margin-top: 0.1em;
    }

    .menu {
      max-width: 50rem;

      .menuItem {
        a {
          font-size: 1.125rem;
        }
      }
    }

    .user {
      margin-left: auto;
      width: auto;

      .info {
        margin-right: 1.25rem;

        p {
          font-size: 1rem;
        }
      }

      button {
        height: $button-desktop-size;
        line-height: $button-desktop-size;
        width: $button-desktop-size;

        .icon {
          &.edit {
            svg {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

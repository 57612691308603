/**
 * Links
 */
$a-tags: 'a, a:active, a:hover, a:visited';
$a-tags-hover: 'a:active, a:hover';

/**
 * Common variables
 */

$buttonHeight: 2rem;
$buttonWidth: 7rem;
$editValueInputWidth: calc(50% - #{$buttonWidth} - 1rem);
$border-radius: 10px;
$button-border-radius: 7rem;
$app-heading-height: 60px;
$app-header-height: 64px;
$appGutters: 2.5rem;
$appContentPadding: 0.5rem;
$appContentMaxWidth: 1262px;
$helpCenterFooterHeight: 144px;
$helpCenterHeaderAndFooterSum: $app-header-height + $helpCenterFooterHeight;
$helpCenterVideoCardWidth: 410px;

/**
 * Colours
 */

$primary: #1bb0a9;
$secondary: #7e72f2;
$bosBrand: #30306f;
$white: #ffffff;
$black: #1c1c1c;
$darkerGrey: #32313c;
$disabledGrey: #838383;
$backgroundSite: #f9f9f9;
$backgroundCard: #f3f3f3;
$backgroundWidget: #dfdfdf;
$stateInactive: #c4c4c4;
$disabled: #838383;
$positive: $primary;
$neutral: #f8cc50;
$negative: #fb1919;
$success: $primary;
$warning: $neutral;
$error: $negative;
$salgBrand: #202146;
$salgwhite: #fcfcfc;

$blue: #00abcd;
$blackLight: #414042;
$darkGrey: #4a4e54;
$sand: #c8bcaf;

// Other colours
$primaryColourLight: #7e72f2;
$black: #1c1c1c;
$darkerGrey: #32313c;
$lightGrey: #c3c3c3;
$disabledGrey: #838383;
$salesWizardGrey: #dfdfdf;
$contentBackground: #f9f9f9;

/**
 * Typography
 */
@mixin defaultTypography() {
  font-family: 'Mulish', Helvetica, Arial, sans-serif;
}
$fontFamilyMuli: 'Mulish', Helvetica, Arial, sans-serif;
/**
 * Font weights
 */
$fontWeightExtraLight: 200;
$fontWeightLight: 300;
$fontWeightNormal: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;
$fontWeightBlack: 900;
/**
 * Responsive breakpoints
 */
$min-width-mobile: 575px;
$max-width-mobile: 768px;
$min-width-tablet: 991px;
$min-width-desktop: 1199px;
$max-width-desktop: 1440px;
$devBlogMaxWidth: 860px;
$devBlogSpacing: 2em;
$devBlogCardWidth: 800px;
$devBlogArticleMaxWidth: 770px;

$small: 'all and (max-width: ' + $min-width-mobile + ')';
$medium: 'all and (max-width: ' + $max-width-mobile + ')';
$large: 'all and (max-width: ' + $min-width-tablet + ')';
$x-large: 'all and (max-width: ' + $min-width-desktop + ')';

/**
 * Checkbox
 */
$checkbox-size-value: 20;
$checkbox-size: $checkbox-size-value * 1px;

/**
 * Order List
 */
$column-picker-button-height: 3rem;
$order-list-header-font-size: 1rem;
$status-icon-size-value: 22;
$status-icon-size: $status-icon-size-value * 1px;

/**
 * Icon font
 */
$icon-font-family: 'bos';
$indicator-up-down: '\e900';
$indicator-up: '\e901';
$indicator-down: '\e902';

/**
  * Variables used for the hover menus in services page
 */
$HoverDescriptionBottomOffsetValue: -150;
$HoverDescriptionBottomOffset: $HoverDescriptionBottomOffsetValue * 1px;

$HoverDescriptionBoxHeightValue: 200;
$HoverDescriptionBoxHeight: $HoverDescriptionBoxHeightValue * 1px;

:export {
  primaryColour: $primary;
  secondaryColour: $secondary;
  blue: $blue;
  blackLight: $blackLight;
  darkGrey: $darkGrey;
  sand: $sand;
  primaryColourLight: $primaryColourLight;
  success: $success;
  warning: $warning;
  error: $error;
  checkboxSize: $checkbox-size-value;
  hoverDescriptionBoxHeight: $HoverDescriptionBoxHeightValue;
  hoverDescriptionBottomOffset: $HoverDescriptionBottomOffsetValue;
  statusIconSize: $status-icon-size-value;
  borderRadius: $border-radius;
}

/**
 * Z-indices
 */

$helpCenterFooterZIndex: 50;

@import 'variables';

hr {
  border: none;
  border-top: 1px solid rgba(50, 50, 60, 0.6);
  margin-bottom: 3rem;
}

:global(input) {
  border: 2px solid #c4c4c4;
}
